.simple-keyboard div.hg-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
}

@supports (-webkit-touch-callout: none) {
  .simple-keyboard {
    padding-bottom: 2.1em;
  }
}

.simple-keyboard button.hg-button {
  flex: 1;
}

.simple-keyboard button[data-skbtn="{ent}"] {
  color: green;
  font-size: 2em;
}

.simple-keyboard button[data-skbtn="{ent}"]:disabled {
  color: gray;
  font-size: 1em;
}

.simple-keyboard button[data-skbtn="{backspace}"] {
  color: #7C0A02;
}
