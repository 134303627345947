.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  text-align: center;
  max-width: 580px;
  margin: auto;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
}

input {
  -webkit-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.App .main {
  height: 100%;
  margin-top: 1em;
  margin-bottom: 2.5em;
}

.App .navFadeIn {
  animation: 0.5s fadeIn forwards;
}

.App .navFadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-80px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(80px, 0);
    opacity: 0;
  }
}