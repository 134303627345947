div.symbol {
  height: 1.2em;
  min-height: 1.2em;
  margin: 0;
  padding: 0;
  padding-top: 0.1em;
  margin-left: 0.1em;

  font-size: 10em;
  text-align: center;
  text-shadow: 1px 1px 3px black;
}

div.symbol-content-- {
  padding-bottom: 0.1em;
  padding-top: 0;
}

div.symbol-type-1 {
  font-weight: lighter;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.fade-exit {
  opacity: 1;
}

div.color-0 {
  color: #DC143C;
}

div.color-1 {
  color: #00008B;
}

div.color-2 {
  color: #0000FF;
}

div.color-3 {
  color: #8A2BE2
}

div.color-4 {
  color: #A52A2A;
}