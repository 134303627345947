div.stars {
  font-size: 2.8em;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 0.1em;
  margin-top: 0.5em;
  color: ghostwhite;

  min-height: 1.2em;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: lightgray;
  -webkit-font-smoothing: antialiased;
}

@supports (-webkit-touch-callout: none) {
  div.stars {
    padding-top: 0.5em;
  }
}

div.stars.small {
  font-size: 1em;
  margin-top: 1.8em;
  padding-left: 1em;
  transform: rotate(-5deg);
}

div.stars>div {
  margin: 0.1em;
}

div.stars>div.achieved {
  margin: 0.025em;
  font-size: 1.1em;
  color: gold;
  -webkit-text-stroke-color: goldenrod;
  text-shadow: 0.02em 0.02em 0.05em black;
  animation: star-shake 0.5s;
  animation-iteration-count: 1;
}

@keyframes star-shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}