div.vertical-expression {
  display: flex;
  justify-content: center;

  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;

  font-size: 1em;
}

div.vertical-expression .expression {
  display: flex;
  justify-content: right;
  flex-direction: column;

  min-height: 1em;

  width: 18em;
  margin: 0;
  padding: 0;
}

div.vertical-expression hr {
  border: 0.4em solid black;
  border-radius: 0.4em;
  width: 100%;
  margin: 0;
  padding-left: 0.5em
}

div.vertical-expression .symbol {
  text-align: right;
  margin: 0;
  padding: 0;
  min-height: auto;
  height: auto;
}

div.vertical-expression .operator {
  text-align: left;
  margin: 0;
  padding: 0;
  min-height: auto;
  height: auto;
}

div.vertical-expression .answer {
  text-align: right;
  margin: 0;
  padding: 0;
  min-height: auto;
  height: auto;
}

div.vertical-expression .symbol-type-1 {
  width: 1em;
  text-align: center;
  margin-top: -0.6em;
  margin-bottom: -0.7em;
  margin-left: -0.75em;
}

div.vertical-expression div.answer input[type="number"] {
  padding-block: 0px;
  padding-inline: 0px;
  padding: 0;
  margin: 0;
}

div.vertical-expression div.answer {
  padding: 0;
  margin: 0;
  padding-left: 0.05em
}

