
div.level {
  position: relative;
  border: 1px solid transparent;
  height: 100%;
}

div.level .keyboard {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
}
