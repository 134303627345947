div.sublevel button {
  border: none;
  background: none;
  font-weight: bold;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

div.sublevel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 7em;

  margin: 0;
  padding: 0;

  font-size: 1em;
}

div.sublevel div.content {
  margin-top: -4em;
  margin-left: 0.5em;
}

div.sublevel div.icon {
  font-size: 5em;
  opacity: 0.9;
  text-shadow: 1px 1px 3px black;
}

@supports (-webkit-touch-callout: none) {
  div.sublevel div.content {
    margin-top: -5em;
  }
}

div.sublevel div.icon:after {
  content: "😊";
}

div.sublevel div.icon.grade-1:after {
  content: "Ⅰ";
}

div.sublevel div.icon.grade-2:after {
  content: "Ⅱ";
}

div.sublevel div.icon.grade-3:after {
  content: "Ⅲ";
}

div.sublevel div.icon.grade-4:after {
  content: "Ⅳ";
}

div.sublevel div.icon.grade-5:after {
  content: "Ⅴ";
}

div.sublevel div.icon.grade-6:after {
  content: "Ⅵ";
}

div.sublevel div.icon.level:after {
  content: "⛳";
}

div.sublevel div.icon.bug:after {
  content: "🐛";
}

div.sublevel div.icon.turtle:after {
  content: "🐢";
}

div.sublevel div.icon.ant:after {
  content: "🐜";
}

div.sublevel div.icon.lady-bug:after {
  content: "🐞";
}

div.sublevel div.icon.chick:after {
  content: "🐤";
}

div.sublevel div.icon.penguin:after {
  content: "🐧";
}

div.sublevel div.icon.rabbit:after {
  content: "🐇";
}

div.sublevel div.icon.leopard:after {
  content: "🐅";
}

div.sublevel div.icon.tiger:after {
  content: "🐆";
}

div.sublevel div.icon.whale:after {
  content: "🐋";
}

div.sublevel div.icon.horse:after {
  content: "🐎";
}

div.sublevel div.icon.brain:after {
  content: "🧠";
}

div.sublevel.disabled div.icon {
  opacity: 0.2;
  text-shadow: 1px 1px 3px gray;
}

div.sublevel div.subject {
  position: relative;
  z-index: 99;
  color: limegreen;
  font-size: 3em;
  opacity: 1;
  min-height: 1em;
  min-width: 1em;
  text-shadow: 0 0 10px black, 0 0 20px black, 0 0 30px black;
  transform-origin: 0 0;
  transform: rotate(-10deg);
}

div.sublevel.disabled div.subject {
  color: gainsboro;
  text-shadow: 1px 1px 3px gray;
}

div.sublevel div.lock-icon {
  z-index: 100;
  font-size: 3em;
  margin-left: 1em;
  margin-top: -0.5em;
  padding-bottom: 0.5em;
  color: gainsboro;
  text-shadow: 1px 1px 3px gray;
}

div.sublevel div.lock-icon:after {
  position: relative;
  z-index: 100;
  content: "🔒";
}

div.sublevel div.lock-icon.shake {
  -webkit-animation: shake 0.6s ease-in-out;
  -moz-animation: shake 0.6s ease-in-out;
  animation: shake 0.6s ease-in-out;
}

div.sublevel.unlocked .icon {
  color: #fff;
  -webkit-animation: glow 0.6s ease-in-out infinite alternate, shake 0.6s ease-in-out;
  -moz-animation: glow 0.6s ease-in-out infinite alternate, shake 0.6s ease-in-out;
  animation: glow 0.6s ease-in-out infinite alternate, shake 0.6s ease-in-out;
}

div.sublevel.warning .icon {
  color: #fff;
  -webkit-animation: warning-glow 1.2s ease-in-out infinite alternate;
  -moz-animation: warning-glow 1.2s ease-in-out infinite alternate;
  animation: warning-glow 1.2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 0.1em #fff, 0 0 0.15em #fff, 0 0 0.2em #9a94e4, 0 0 0.25em #9a94e4, 0 0 0.3em #9a94e4;
  }

  to {
    text-shadow: 0 0 0.25em #fff, 0 0 0.25em #fff, 0 0 0.30em #9a94e4, 0 0 0.35em #9a94e4, 0 0 0.4em #9a94e4, 0 0 0.5em #9a94e4, 0 0 0.6em #9a94e4;
  }
}

@keyframes warning-glow {
  from {
    text-shadow: 0 0 0.1em #fff, 0 0 0.15em #fff, 0 0 0.2em #c95f22, 0 0 0.25em #c95f22, 0 0 0.3em #c95f22;
  }

  to {
    text-shadow: 0 0 0.25em #fff, 0 0 0.25em #fff, 0 0 0.30em #c95f22, 0 0 0.35em #c95f22, 0 0 0.4em #c95f22, 0 0 0.5em #c95f22, 0 0 0.6em #c95f22;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
