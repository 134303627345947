div.memory-expression {
  display: flex;
  justify-content: center;
  flex-direction: row;

  width: 100%;
  margin: 0;
  padding: 0;

  font-size: 0.5em;
  min-height: 1em;
}

div.memory-expression .timer {
  padding-top: 2em;
}

div.memory-expression .timer.hidden {
  display: none;
}

div.memory-expression .symbol {
  margin-left: 0;
}

div.memory-expression .answer {
  margin-left: 0;
}

div.memory-expression .answer .size_1 {
  margin-left: 0;
}

div.memory-expression .answer .size_2 {
  margin-left: 0;
}

div.memory-expression .answer .size_3 {
  margin-left: 0;
}
