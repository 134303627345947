div.expression {
  display: flex;
  justify-content: center;
  flex-direction: row;

  width: 100%;
  margin: 0;
  padding: 0;

  font-size: 0.4em;
  min-height: 1em;
}
