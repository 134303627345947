.checkbox {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;

  font-size: 5em;
  padding-top: 0.2em;
  padding-bottom: 0.4em;
  text-align: center;
}

.checkbox [type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.checkbox [type="checkbox"]:not(:checked) + label,
.checkbox [type="checkbox"]:checked + label {
  position: relative;
  cursor: pointer;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.checkbox [type="checkbox"]:not(:checked) + label:before,
.checkbox [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  width: 0.5em;
  height: 0.5em;
  left: -0.25em;
  border: 1px solid skyblue;
  background-color: lightskyblue;
  border-radius: 0.1em;
  box-shadow: inset 0 0.01em 0.03em rgba(135, 206, 250, 0.3)
}

.checkbox [type="checkbox"]:not(:checked) + label:after,
.checkbox [type="checkbox"]:checked + label:after {
  content: '\2713';
  position: absolute;
  top: -0.4em;
  left: -0.3em;
  color: darkblue;
  transition: all .2s;
}

.checkbox [type="checkbox"]:not(:checked) + label:after {
  color: whitesmoke;
  top: -0.3em;
  left: -0.4em;
  transform: scale(0.5);
}

@supports (-webkit-touch-callout: none) {
  .checkbox [type="checkbox"]:not(:checked) + label:after,
  .checkbox [type="checkbox"]:checked + label:after {
    top: -0.5em;
    left: -0.5em;
  }
}

.checkbox [type="checkbox"]:disabled + label:after {
  content: '';
  position: absolute;
  width: 0.5em;
  height: 0.5em;
  top: 0;
  left: -0.25em;
  border: 1px solid whitesmoke;
  border-radius: 0.1em;
}
