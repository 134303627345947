div.bottombar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: space-between;

  position: fixed;
  height: 2.5em;
  left: 0;
  bottom: 0;
  width: 100%;

  background-color: white;
  border-top: 1px solid whitesmoke;
}

div.bottombar button {
  font-size: 1.6em;
  border: none;
  background: none;
  font-weight: bold;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
