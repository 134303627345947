
div.levels {

  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;

  width: 100%;
  height: 100%;

  margin: 0;
  margin-top: 1.5em;
  padding: 0;

  font-size: 1em;
}

div.levels > .sublevel {
  margin: 0.5em 0.1em;
}
