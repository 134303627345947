div.answer {
  height: 1.2em;
  min-height: 1.2em;
  margin: 0;
  margin-left: 0.05em;
  padding: 0;
  padding-top: 0.1em;

  font-weight: bold;
  font-size: 10em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-align: center;
}

@supports (-webkit-touch-callout: none) {
  div.answer input.number {
    height: 1.2em;
    -webkit-align-self: center;
    align-self: center;
    line-height: normal !important;
  }
}

div.answer input.number {
  padding: 0;
  font-size: 1em;
  font-weight: bold;
  border: 1px solid whitesmoke;
  border-radius: 15px;
  text-align: center;
  color: darkblue;
  text-shadow: 1px 1px 3px black;
  caret-color: transparent;
  line-height: normal !important;
}

input.number::-webkit-input-placeholder {
  -webkit-align-self: center;
  align-self: center;
  line-height: normal !important;
}

input.number::-webkit-inner-spin-button,
input.number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.answer.size_1 input.number {
  width: 0.6em;
}

div.answer.size_2 input.number {
  width: 1.2em;
}

div.answer.size_3 input.number {
  width: 1.8em;
}

div.answer.size_4 input.number {
  width: 2.4em;
}

div.answer input.number::placeholder {
  color: lightskyblue;
  opacity: 0.6;
  text-shadow: none;
}

div.answer input.number:disabled {
  background-color: transparent;
  color: lightskyblue;
}

div.answer input.number:focus {
  outline: none;
  border: 1px solid transparent;
  box-shadow: inset 0 0 5px skyblue;
}

div.answer .mistake.show input.number {
  display: none;
}

div.answer .mistake .correctAnswer {
  display: none;
}

div.answer .mistake.show .correctAnswer {
  display: block;
  border: 1px solid transparent;
  width: 0.6em;
  color: black;
  font-weight: bold;
  text-shadow: 2px 2px 20px red;
}
