div.topbar {
  z-index: 1000;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0.25em 0;
  height: 1em;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 1.2em;
  font-weight: bold;

  color: lightskyblue;
  border-bottom: 1px inset whitesmoke;
  background-color: white;
}

div.topbar div {
  margin: 0 0.6em;
}

@supports (-webkit-touch-callout: none) {
  div.topbar {
    padding-bottom: 0.6em;
  }
}

div.topbar div:before,
div.after {
  margin-right: 0.25em;
}

div.topbar .name::before {
  opacity: 0.6;
  content: "👶";
}

div.topbar .score::before {
  color: goldenrod;
  content: "✨";
}

div.topbar .score {
  text-align: right;
  width: 5em;
}

div.topbar .add-score {
  position: relative;

  margin: 0;
  margin-top: -1em;
  width: 5em;

  opacity: 0;
  text-align: right;
  color: goldenrod;
}

div.topbar .add-score.show {
  animation: add-score-fade-in 1.5s;
}

div.topbar .add-score::before {
  color: goldenrod;
  content: "✚";
}

@keyframes add-score-fade-in {
  from {
    opacity: 1;
    top: 0;
  }

  to {
    opacity: 0;
    top: 1.5em;
  }
}