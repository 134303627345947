form.profile {
  padding: 0;
  padding-top: 5em;
}

form.profile input {
  font-size: 2em;
  font-weight: normal;
  border: 1px solid whitesmoke;
  text-align: center;
  color: darkblue;
  caret-color: darkblue;
  text-shadow: 1px 1px 3px black;
}

form.profile input[type="text"] {
  width: 8em;
  margin-bottom: 1em;
}

form.profile input[type="number"] {
  width: 5em;
  margin-bottom: 1em;
}

form.profile input::placeholder {
  color: lightskyblue;
  opacity: 0.6;
  text-shadow: none;
}

form.profile .username.label:before {
  position: absolute;
  font-size: 2em;
  content: "👶";
}

form.profile .age.label:before {
  position: absolute;
  font-size: 2em;
  content: "⌛";
}
