div.answer-composite {
  display: flex;
  justify-content: right;
  flex-direction: row-reverse;

  height: 100%;
  min-height: 1.2em;
  margin: 0;
  padding: 0;
  padding-top: 0.1em;
  padding-left: 0.2em;

  width: 100%;
}

div.answer-composite .mistake {
  display: inline-flex;
}

div.answer-composite input[type="number"]::placeholder {
  color: lightskyblue;
  opacity: 0.6;
  text-shadow: none;
}

div.answer-composite div.answer.size_1 input[type="number"] {
  width: 0.6em;
  padding: 0;
}

div.answer-composite input[type="number"] {
  padding-block: 0px;
  padding-inline: 0px;

  font-size: 1em;
  font-weight: bold;
  border: 0px solid transparent;
  text-align: center;
  color: darkblue;
  caret-color: darkblue;
  text-shadow: 1px 1px 3px black;
}

div.answer-composite input[type="number"]:focus {
  outline: none;
  border: 0px solid whitesmoke;
}

